import { gql } from '@apollo/client';
import {
  MeCustomerFragment,
  MeLeadFragment,
  MeOperatorFragment,
  MePartnerAgentFragment,
  UTMFragment,
} from '../fragments';

export const GET_ME = gql`
  ${MeLeadFragment}
  ${MeCustomerFragment}
  ${MeOperatorFragment}
  ${MePartnerAgentFragment}
  query me {
    me {
      id
      ... on Lead {
        ...MeLead
      }
      ... on Customer {
        ...MeCustomer
      }
      ... on Operator {
        ...MeOperator
      }
      ... on PartnerAgent {
        ...MePartnerAgent
        created
      }
    }
  }
`;

export const UPDATE_LEAD = gql`
  ${UTMFragment}
  mutation updateLead($id: ID!, $lead: LeadInput!) {
    updateLead(id: $id, lead: $lead) {
      id
      landingPage
      referrer
      utm {
        ...UTM
      }
    }
  }
`;

export const INIT_LEAD = gql`
  mutation initLead {
    initLead {
      id
    }
  }
`;

export const REINITIALIZE_SESSION = gql`
  mutation reinitializeSession($isAuthenticated: Boolean) {
    reinitializeSession(isAuthenticated: $isAuthenticated)
  }
`;

export const LOGIN_CUSTOMER_WITH_MAGIC_LINK = gql`
  mutation loginCustomerWithMagicLink($token: String!) {
    loginCustomerWithMagicLink(token: $token) {
      ... on Customer {
        id
        firstName
        lastName
        email
        phone
        intercomHash
      }
      ... on CustomerLoginErrorResult {
        error
      }
    }
  }
`;

export const LOGIN_AND_GET_CUSTOMER_WITH_MAGIC_LINK = gql`
  ${MeCustomerFragment}
  mutation loginAndGetCustomerWithMagicLink($token: String!) {
    loginAndGetCustomerWithMagicLink(token: $token) {
      ... on Customer {
        id
        ...MeCustomer
      }
      ... on CustomerLoginErrorResult {
        error
      }
    }
  }
`;
