import React, { useCallback, useContext, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { getIntercomUserData } from '~/components/_app/initializeSession';
import type { Dispatch, SetStateAction } from 'react';
import type { me_me, me_me_InstallerAgent } from '../types/generated/me';

export type Me = Exclude<me_me, me_me_InstallerAgent>;

type ContextValue = [Me | undefined, Dispatch<SetStateAction<Me | undefined>> | undefined];

export const MeContext = React.createContext<ContextValue>([undefined, undefined]);

export const MeProvider: React.FC<{ initialMe?: Me }> = ({ children, initialMe }) => {
  const [me, setMe] = useState<Me | undefined>(initialMe);
  const contextValue: ContextValue = [me, setMe];
  return <MeContext.Provider value={contextValue}>{children}</MeContext.Provider>;
};

/**
 * Can be used safely on protected routes: Me will be defined before the first render.
 * Use useMeOptional otherwise!
 */
export function useMe<T extends Me = Me>() {
  const [me, setMe] = useContext(MeContext);
  const intercom = useIntercom();

  const handleSetMe = useCallback(
    (newMe?: Me) => {
      setMe?.(newMe);
      if (newMe?.__typename === 'Customer' || newMe?.__typename === 'PartnerAgent') {
        const attrs = getIntercomUserData(newMe);
        intercom.update(attrs);
      }
    },
    [intercom, setMe],
  );

  return {
    me: me as T,
    setMe: handleSetMe,
  };
}

/**
 * Should be used on public routes, and in components used in public routes,
 * where the content will be rendered before ME might be fetched.
 * This is so that a the page can be statically rendered by NextJS without having ME available
 * could also name it useMeMaybe ;)
 */
export function useMeOptional<T extends Me = Me>() {
  const { me, setMe } = useMe();
  return {
    me: me as T | undefined,
    setMe: setMe,
  };
}
