import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useInterval } from '../hooks/useInterval';

interface DelayedRedirectProps {
  timeout: number;
  to: string;
  interval?: number;
  onInterval?: (ms: number) => void;
}

/**
 * This can probably be replaced with a hook or something
 * but for simplicities sake right now this should work ok
 */
const DelayedRedirect: React.FC<DelayedRedirectProps> = ({
  timeout,
  to,
  interval = 100,
  onInterval,
}) => {
  const router = useRouter();
  const [countdown, setCountdown] = useState<number>(timeout);
  const countDown = () => {
    setCountdown(Math.max(0, countdown - interval));
    if (onInterval) onInterval(countdown - interval);
  };

  useEffect(() => {
    if (countdown === 0) {
      void router.push(to);
    }
  }, [countdown, router, to]);

  useInterval(countDown, interval);

  return <></>;
};

export default DelayedRedirect;
