import { useLazyQuery, useQuery } from '@apollo/client';
import { isEqual as lodashIsEqual } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  AVAILABLE_ORDER_ITEMS_BY_DOSSIER_DATA,
  ORDER_ITEMS_BY_KEYS_WITH_PRICES,
  ORDER_ITEM_BY_KEY_WITH_PA_PRICE,
  ORDER_ITEM_BY_KEY_WITH_PRICE,
} from '../queries/orderItem';
import { DutyPackageName, PartnerMortgageLender } from '../types/graphql-global-types';
import { useActiveHouseId } from './useActiveHouseId';
import { useIsInitializing } from './useIsInitializing';
import { useMe } from './useMe';
import type {
  availableOrderItemsByDossierData_availableOrderItemsByDossierData as OrderItemWithPrice,
  availableOrderItemsByDossierData,
  availableOrderItemsByDossierDataVariables,
} from '../types/generated/availableOrderItemsByDossierData';
import type { me_me_PartnerAgent } from '../types/generated/me';
import type {
  orderItemByKeyWithPAPrice,
  orderItemByKeyWithPAPriceVariables,
} from '../types/generated/orderItemByKeyWithPAPrice';
import type {
  orderItemByKeyWithPrice,
  orderItemByKeyWithPriceVariables,
} from '../types/generated/orderItemByKeyWithPrice';
import type {
  orderItemsByKeysWithPrices,
  orderItemsByKeysWithPricesVariables,
  orderItemsByKeysWithPrices_orderItemsByKeys,
} from '../types/generated/orderItemsByKeysWithPrices';
import type { PartnerCollectiveKey } from '../types/graphql-global-types';
import type { DutyPackageWithPrice } from '../utils/dutyPackages';

const customerFriendlyErrorMsg =
  'Er is iets mis gegaan, onze ontwikkelaars zijn op de hoogte gebracht. Probeer het later nog eens.';

function deriveAdditions(
  pkgs: readonly orderItemsByKeysWithPrices_orderItemsByKeys[],
): DutyPackageWithPrice[] {
  return pkgs.map((pkg, index) => {
    if (index === 0) return { ...pkg, additions: [] };
    const prevPkg = pkgs[index - 1];
    const isExtension = prevPkg.features?.every(f => pkg.features?.includes(f));
    const additions = isExtension
      ? pkg.features?.filter(f => !prevPkg.features?.includes(f))
      : undefined;
    return {
      ...pkg,
      features:
        isExtension && additions ? [`Alles van ${prevPkg.fullName}`, ...additions] : pkg.features,
      additions,
      additionsColor: isExtension ? prevPkg.color : pkg.additionsColor ?? '',
      price: pkg.price || pkg.nonDiscountedPrice,
    };
  });
}

export const useCustomerDutyPackages = (
  dutyPackages: (DutyPackageName | string)[] = [DutyPackageName.quotesLead],
) => {
  const { isInitializing } = useIsInitializing();
  const [shouldFetchNewPackages, setShouldFetchNewPackages] = useState(true);
  // const { activeHouseId: houseId } = useActiveHouseId();

  // lazy query that's only called when necessary. Not when houseId changes: that causes weird render behavior
  const [fetchDutyPackages, { data: packageData, error, loading }] = useLazyQuery<
    orderItemsByKeysWithPrices,
    orderItemsByKeysWithPricesVariables
  >(ORDER_ITEMS_BY_KEYS_WITH_PRICES, {
    variables: {
      keys: dutyPackages,
      // even though this could be an operator/partner-agent/etc., fetch customer packages as if you're a lead
      // I originally wanted to always use the actual user ID/type and house ID, but that turned out to be problematic
      requesterId: '',
      requesterType: 'Lead',
      // setting the current house ID here is nice for debugging, but not required.
      // Removed for now since it causes weird re-render behavior that remounts the page
      houseId: '',
    },
    fetchPolicy: 'cache-first',
    onError: e => console.error('Could not load duty packages', dutyPackages, e),
    onCompleted: () => setShouldFetchNewPackages(false),
  });

  // The dutyPackages passed into this hook change every rerender, but we don't want to re-fetch every rerender
  const currentPackages = useRef(dutyPackages);
  useEffect(() => {
    if (
      dutyPackages.length &&
      currentPackages.current.length &&
      !lodashIsEqual(currentPackages.current, dutyPackages)
    ) {
      setShouldFetchNewPackages(true);
    }
  }, [dutyPackages]);

  useEffect(() => {
    if (shouldFetchNewPackages && !isInitializing) void fetchDutyPackages();
  }, [fetchDutyPackages, isInitializing, shouldFetchNewPackages]);

  // Derive "additions" when fetching multiple pkgs instead of storing in DB
  // TODO: Can remove additionsColor from backend
  const packages = useMemo(
    () => deriveAdditions(packageData?.orderItemsByKeys || []),
    [packageData?.orderItemsByKeys],
  );

  return {
    packages,
    error: error ? new Error(customerFriendlyErrorMsg) : undefined,
    loading,
  };
};

export const useCustomerDutyPackage = (dutyPackage: DutyPackageName | string) => {
  const { activeHouseId: houseId } = useActiveHouseId();
  const [initialHouseId] = useState(houseId);
  const { isInitializing } = useIsInitializing();

  const {
    data: packageData,
    error,
    loading,
  } = useQuery<orderItemByKeyWithPrice, orderItemByKeyWithPriceVariables>(
    ORDER_ITEM_BY_KEY_WITH_PRICE,
    {
      variables: {
        key: dutyPackage,
        requesterId: '',
        requesterType: 'Lead',
        // when house ID changes, avoid re-fetching the package: would be nice in theory, but it breaks re-renders (RequestForm disappears)
        houseId: initialHouseId,
      },
      skip: isInitializing,
      onError: e => console.error('Could not load duty package', dutyPackage, e),
    },
  );

  return {
    dutyPackage: packageData?.orderItemByKey || undefined,
    error: error ? new Error(customerFriendlyErrorMsg) : undefined,
    loading,
  };
};

export const usePartnerAgentDutyPackage = (
  dutyPackage: DutyPackageName | string,
  mortgageLender: PartnerMortgageLender | null,
  hasGreenMortgage: boolean,
  collective: PartnerCollectiveKey | null,
) => {
  const { me } = useMe();
  const { isInitializing } = useIsInitializing();

  const {
    data: packageData,
    error,
    loading,
  } = useQuery<orderItemByKeyWithPAPrice, orderItemByKeyWithPAPriceVariables>(
    ORDER_ITEM_BY_KEY_WITH_PA_PRICE,
    {
      variables: {
        key: dutyPackage,
        requesterId: me.id,
        requesterType: me.__typename,
        mortgageLender,
        collective,
        hasGreenMortgage,
      },
      skip: isInitializing,
      onError: e => console.error('Could not load duty package', dutyPackage, e),
    },
  );

  const standardizedDutyPackage: DutyPackageWithPrice | undefined = useMemo(
    () =>
      packageData
        ? ({
            ...packageData.orderItemByKey,
            price: packageData.orderItemByKey?.priceByDossier,
          } as DutyPackageWithPrice)
        : undefined,
    [packageData],
  );

  return {
    // "package" is a reserved keyword :/
    dutyPackage: standardizedDutyPackage,
    error: error ? new Error(customerFriendlyErrorMsg) : undefined,
    loading,
  };
};

export const getLabelDutyPackageForHouseType = (houseType?: number) => {
  if (!houseType) return;
  switch (true) {
    case houseType === 1:
      return DutyPackageName.energyLabelHouseTypeDetached;
    case houseType === 2:
    case houseType === 22:
      return DutyPackageName.energyLabelHouseTypeDuplex;
    case houseType === 3:
    case houseType === 4:
    default:
      return DutyPackageName.energyLabelHouseTypeRow;
    case houseType >= 5 && houseType < 22:
      return DutyPackageName.energyLabelHouseTypeApartment;
  }
};

export const useAvailableSamenPackages = (
  mortgageLender: PartnerMortgageLender | null,
  hasGreenMortgage: boolean,
  collective: PartnerCollectiveKey | null,
  options: {
    includeSupplemental?: boolean;
    includeEnergyLabel?: boolean;
    houseType?: number;
    loadingHouseType?: boolean;
  } = {},
) => {
  const { me } = useMe<me_me_PartnerAgent>();
  const isMortgageLenderAgent = !!me.office.company.mortgageLender;
  const isInCollective = !!me.office.collectives.length;

  const addPriceToPackages = (dps: OrderItemWithPrice[]) =>
    dps.map(({ orderItem, price }) => ({
      ...orderItem,
      price,
    }));

  const {
    data: ownPkgsData,
    loading,
    error,
  } = useQuery<availableOrderItemsByDossierData, availableOrderItemsByDossierDataVariables>(
    AVAILABLE_ORDER_ITEMS_BY_DOSSIER_DATA,
    {
      variables: {
        requesterId: me.id,
        requesterType: me.__typename,
        hasGreenMortgage,
      },
      onError: e => console.error('Could not load Samen duty packages for', me.id, e),
    },
  );

  const {
    dutyPackage: energyLabelPackage,
    // error: errorEL, // removed error so even without this DP in the DB, the page still works
  } = usePartnerAgentDutyPackage(
    DutyPackageName.energyLabelHouseTypeRow, // Just to show a single pkg, will pass correct pkg in mutation vars
    mortgageLender,
    hasGreenMortgage,
    collective,
  );

  const [
    fetchPackagesWithMortgageLender,
    { data: mlPkgsData, loading: loadingML, error: errorML },
  ] = useLazyQuery<availableOrderItemsByDossierData, availableOrderItemsByDossierDataVariables>(
    AVAILABLE_ORDER_ITEMS_BY_DOSSIER_DATA,
    {
      variables: {
        requesterId: me.id,
        requesterType: me.__typename,
        mortgageLender,
        hasGreenMortgage,
        collective,
      },
      onError: e => console.error('Could not load Samen duty packages for', me.id, e),
    },
  );

  const [
    fetchPackagesWithCollective,
    { data: collPkgsData, loading: loadingColl, error: errorColl },
  ] = useLazyQuery<availableOrderItemsByDossierData, availableOrderItemsByDossierDataVariables>(
    AVAILABLE_ORDER_ITEMS_BY_DOSSIER_DATA,
    {
      variables: {
        requesterId: me.id,
        requesterType: me.__typename,
        hasGreenMortgage,
        collective,
      },
      onError: e => console.error('Could not load Samen duty packages for', me.id, e),
    },
  );

  useEffect(() => {
    if (!mlPkgsData && !errorML && !loadingML && mortgageLender)
      void fetchPackagesWithMortgageLender();
  }, [errorML, fetchPackagesWithMortgageLender, loadingML, mlPkgsData, mortgageLender]);

  useEffect(() => {
    if (!collPkgsData && !errorColl && !loadingColl && collective)
      void fetchPackagesWithCollective();
  }, [collPkgsData, collective, errorColl, fetchPackagesWithCollective, loadingColl]);

  const ownPackages = useMemo(
    () =>
      [...(ownPkgsData?.availableOrderItemsByDossierData || [])].filter(
        pkg => !pkg.orderItem.key.includes('LabelHouseType'),
      ),
    [ownPkgsData?.availableOrderItemsByDossierData],
  );
  const mortgageLenderPackages = useMemo(
    () => mlPkgsData?.availableOrderItemsByDossierData || [],
    [mlPkgsData?.availableOrderItemsByDossierData],
  );
  const collectivePackages = useMemo(
    () => collPkgsData?.availableOrderItemsByDossierData || [],
    [collPkgsData?.availableOrderItemsByDossierData],
  );

  const packagesOverriddenByMortgageLender = useMemo(
    () =>
      !isMortgageLenderAgent &&
      !!ownPackages.length &&
      !!mortgageLenderPackages.length &&
      !lodashIsEqual(ownPackages, mortgageLenderPackages),
    [mortgageLenderPackages, isMortgageLenderAgent, ownPackages],
  );

  const packagesOverriddenByCollective = useMemo(
    () =>
      isInCollective &&
      !!ownPackages.length &&
      !!collectivePackages.length &&
      !lodashIsEqual(ownPackages, collectivePackages),
    [collectivePackages, isInCollective, ownPackages],
  );

  const packagesToReturn: OrderItemWithPrice[] = useMemo(() => {
    let pkgs = [...ownPackages];
    if (mortgageLenderPackages.length) pkgs = [...mortgageLenderPackages];
    else if (collectivePackages.length) pkgs = [...collectivePackages];
    if (
      energyLabelPackage &&
      options.includeEnergyLabel &&
      mortgageLender !== PartnerMortgageLender.triodos // Disabled for Triodos
    ) {
      pkgs.push({
        orderItem: energyLabelPackage,
        price: 0,
        __typename: 'OrderItemWithPrice',
      });
    }
    // Remove any duplicate OrderItems in the returned arrays
    const removedDupes = pkgs.filter(
      (item, index, self) => index === self.findIndex(v => v.orderItem.id === item.orderItem.id),
    );

    const labelPkgs = removedDupes.filter(v => v.orderItem.key.includes('energyLabelHouseType'));
    const nonLabelPkgs = removedDupes.filter(
      v => !v.orderItem.key.includes('energyLabelHouseType'),
    );

    // Return empty array until house type has been loaded, so it doesn't rerender on load
    if (options.loadingHouseType) return [];

    /**
     * This is quite messy, mostly cause of the /snelaanvraag where we need to show a package without knowing a houseType and don't want to rerender the package cards when an address is filled in...
     * So it doesn't actually change the `PackageCard`'s `dutyPackage` prop, just highlights the correct price.
     * The dutyPackage is finally corrected on submit so the correct one is requested.
     */
    const filtered = removedDupes.filter(pkg => {
      // If there are no label packages available, no need to continue
      if (labelPkgs.length === 0) return true;

      // Remove all energy label packages if should be filtered out
      if (!options.includeEnergyLabel) return !labelPkgs.includes(pkg);

      // Remove all label packages but the one for the current house type
      const houseTypePkg = getLabelDutyPackageForHouseType(options.houseType);
      const labelPkg = labelPkgs.find(pkg => pkg.orderItem.key === houseTypePkg);
      if (labelPkg === pkg) return true;

      // Return all non-label packages
      // Or a random energyLabel package, so you can select it on /snelaanvraag, when we don't know the house type yet
      return nonLabelPkgs.includes(pkg) || (!options.houseType && labelPkgs[0] === pkg);
    });

    return filtered;
  }, [
    ownPackages,
    mortgageLenderPackages,
    collectivePackages,
    energyLabelPackage,
    options.includeEnergyLabel,
    options.loadingHouseType,
    options.houseType,
    mortgageLender,
  ]);

  const partnerPackages: DutyPackageWithPrice[] = useMemo(
    () =>
      deriveAdditions(
        addPriceToPackages([...packagesToReturn]).filter(
          pkg => !pkg.isSupplemental || options.includeSupplemental,
        ) || [],
      ),
    [options.includeSupplemental, packagesToReturn],
  );

  return {
    partnerPackages,
    error: errorML || errorColl || error ? new Error(customerFriendlyErrorMsg) : undefined,
    loading: loadingML || loadingColl || loading,
    packagesOverridden: packagesOverriddenByMortgageLender || packagesOverriddenByCollective,
  };
};

export const dutyPackagesWithoutIntake: DutyPackageName[] = [
  DutyPackageName.drutenWijchen,
  DutyPackageName.budgetThuisNoIntake,
];

export const dutyPackageIDsWithoutIntake: string[] = [
  '65731c98c3277e78561c0a3f', // DutyPackageName.drutenWijchen,
  '6694de6a6195589759fddb49', // DutyPackageName.budgetThuisNoIntake,
];
