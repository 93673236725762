import React, { useEffect, useState } from 'react';
import { useActiveHouseProgress } from '~/hooks/useActiveHouseProgress';
import { dutyPackageIDsWithoutIntake, useCustomerDutyPackages } from '~/hooks/useDutyPackage';
import { useFunnel } from '~/hooks/useFunnel';
import { useMeOptional } from '~/hooks/useMe';
import { DutyPackageName } from '~/types/graphql-global-types';
import type { Dispatch, SetStateAction } from 'react';

export type FunnelRequestType = 'advice' | 'quotes' | 'none';

type ContextValue = [FunnelRequestType, Dispatch<SetStateAction<FunnelRequestType>>];
export const FunnelRequestTypeContext = React.createContext<ContextValue>(['quotes', () => null]);

/**
 * This contains the logic required to determine what the end stage of a user's current funnel is.
 * Can be either `advice`, `quotes` or `none`, depending on the customer, duty package and/or intake bucket.
 * Defaults to `quotes`.
 */
export const FunnelRequestTypeProvider: React.FC = ({ children }) => {
  const [funnelRequestType, setFunnelRequestType] = useState<FunnelRequestType>('quotes');
  const contextValue: ContextValue = [funnelRequestType, setFunnelRequestType];

  return (
    <FunnelRequestTypeContext.Provider value={contextValue}>
      {children}
    </FunnelRequestTypeContext.Provider>
  );
};

/** B2B packages that can be requested by the customer in 2025 */
const legacySupportedDutyPackages = [
  DutyPackageName.asrComplete,
  DutyPackageName.budgetThuisComplete,
  DutyPackageName.drutenWijchen,
  DutyPackageName.floriusGreen,
  DutyPackageName.fritsComplete,
  DutyPackageName.hypotheekVisieComplete,
  DutyPackageName.vanLanschotKempenComplete,
  DutyPackageName.veldsinkComplete,
  DutyPackageName.verbruggeComplete,
  DutyPackageName.vennComplete,
  DutyPackageName.vvaaComplete,
];

const useFunnelRequestType = () => {
  const contextValue = React.useContext(FunnelRequestTypeContext);
  if (!contextValue) {
    throw new Error('useFunnelRequestType must be used within a FunnelRequestTypeProvider');
  }

  const { me } = useMeOptional();
  const [funnelRequestType, setFunnelRequestType] = contextValue;

  const { data, loading: houseLoading } = useActiveHouseProgress();
  const { dutyPackage: funnelDutyPackage } = useFunnel();

  const orderItemId = data?.house.orders[0]?.orderLines[0].orderItem.id;
  const { packages: legacyAdvicePackages, loading: legacyPackagesLoading } =
    useCustomerDutyPackages(legacySupportedDutyPackages);

  const isLegacyCustomer = me?.__typename === 'Customer' && me.isLegacyCustomer;
  const hasHouseVisit = !!data?.house.intake.isPlanned;
  const shouldUseLegacyFlow =
    legacyAdvicePackages.some(dp => dp.id === orderItemId || dp.id === funnelDutyPackage?.id) ||
    isLegacyCustomer;

  const canSkipIntake = dutyPackageIDsWithoutIntake.some(id => id === orderItemId);
  const isRedBucket = data?.customerIntakeByHouse?.bucket === 'red';

  useEffect(() => {
    if (isRedBucket || (isLegacyCustomer && hasHouseVisit)) return setFunnelRequestType('none');
    if (shouldUseLegacyFlow) return setFunnelRequestType('advice');
  }, [setFunnelRequestType, isRedBucket, shouldUseLegacyFlow, isLegacyCustomer, hasHouseVisit]);

  return {
    funnelRequestType,
    setFunnelRequestType,
    canSkipIntake,
    loading: legacyPackagesLoading || houseLoading,
  };
};

export default useFunnelRequestType;
