import { gql, NetworkStatus, useQuery } from '@apollo/client';
import React, { useContext, useEffect } from 'react';
import type {
  activeHouseNavigationHelper,
  activeHouseNavigationHelperVariables,
} from '~/types/generated/activeHouseNavigationHelper';
import { BasicAddressFragment } from '../fragments';
import { useActiveHouseId } from './useActiveHouseId';
import type { AtLeast } from '@energiebespaarders/constants';
import type { QueryResult } from '@apollo/client';

/** Generic high-level query for navigation and account environment, should prevent fetching details for each of the connected nodes */
const ACTIVE_HOUSE_NAVIGATION_HELPER = gql`
  ${BasicAddressFragment}
  query activeHouseNavigationHelper($houseId: ID!) {
    house(id: $houseId) {
      id
      ...BasicAddress
      orders {
        id
        created
        isPaid
        isCancelled
        totalToBePaid
        duties {
          name
        }
        orderLines {
          orderItem {
            id
          }
        }
      }
      requestedDuties {
        name
      }
      advice {
        id
        isRequested
        isSent
        isPaid
      }
      intake {
        id
        appointment {
          plannedFor
          plannedOn
        }
        isPlanned
        isStarted
        forQuote
      }
      sentQuotes {
        id
        isExpired
        isAccepted
        sentOn
        acceptedOn
        solution
        expirationDate
        deal {
          id
        }
      }
      quoteRequests {
        id
        interests
      }
      customer {
        id
        isLegacyCustomer
      }
    }
    customerIntakeByHouse(houseId: $houseId) {
      submittedOn
      canPlanOwnVisit
      bucket
      interests
    }
  }
`;

type ActiveHouseProgressContextValue = AtLeast<
  QueryResult<activeHouseNavigationHelper, activeHouseNavigationHelperVariables>,
  'loading' | 'startPolling' | 'stopPolling' | 'networkStatus'
>;

export const ActiveHouseProgressContext = React.createContext<ActiveHouseProgressContextValue>({
  loading: true,
  startPolling: () => null,
  stopPolling: () => null,
  networkStatus: NetworkStatus.loading,
});

export const ActiveHouseProgressProvider: React.FC = ({ children }) => {
  const { activeHouseId } = useActiveHouseId();
  const res = useQuery<activeHouseNavigationHelper, activeHouseNavigationHelperVariables>(
    ACTIVE_HOUSE_NAVIGATION_HELPER,
    {
      variables: { houseId: activeHouseId },
      skip: !activeHouseId,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    if (res.error) console.error('Error fetching active house progress:', res.error);
    // This makes loading a dependency for this whole hook
  }, [res.error, res.loading]);

  return (
    <ActiveHouseProgressContext.Provider value={res}>
      {children}
    </ActiveHouseProgressContext.Provider>
  );
};

export function useActiveHouseProgress(): ActiveHouseProgressContextValue {
  const contextValue = useContext(ActiveHouseProgressContext);
  if (!contextValue) {
    throw new Error('useActiveHouseProgress must be used within a ActiveHouseProgressProvider');
  }
  return contextValue;
}
