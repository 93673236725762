import { Box, Input, theme } from '@energiebespaarders/symbols';
import { Mail } from '@energiebespaarders/symbols/icons/solid';
import { useState, type ChangeEvent } from 'react';

import { Center, Small } from '@energiebespaarders/symbols/helpers';
import emailSpellChecker from '@zootools/email-spell-checker';
import { rgba } from 'polished';
import styled from 'styled-components';
import { useDebouncedValue } from '~/hooks/useDebounce';

const Banner = styled(Box)<{ $color: string; $bgColor: string; $borderColor: string }>`
  background: ${x => rgba(x.$bgColor, 0.3)};
  color: ${x => x.$color};
  border: 0.5px solid ${x => x.$borderColor};
  border-radius: 6px;
  padding: 0.5rem;
  margin-bottom: 1rem;
  u {
    cursor: pointer;
  }
`;

type ValidatedEmailInputProps = {
  onChange: (value: string) => void;
  value: string;
  label?: string;
  touched?: boolean;
  error?: string | null;
  disabled?: boolean;
  fontSize?: number;
};

const ValidatedEmailInput: React.FC<ValidatedEmailInputProps> = ({
  label,
  value,
  onChange,
  touched,
  error,
  disabled,
  fontSize,
}) => {
  // Optimization: Could improve this by dynamically importing the email checker library
  // https://nextjs.org/docs/pages/building-your-application/optimizing/lazy-loading#with-external-libraries

  const [suggestion, setSuggestion] = useState<string | null>(null);
  const debouncedSuggestion = useDebouncedValue(suggestion, 1000);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const suggestion = emailSpellChecker.run({
      email: e.target.value,
    });

    if (suggestion) {
      setSuggestion(suggestion.full);
    } else {
      setSuggestion(null);
    }

    onChange(e.target.value);
  };

  const handleSuggestionClick = () => {
    if (suggestion) {
      onChange(suggestion);
    }
    setSuggestion(null);
  };

  return (
    <>
      <Input
        type="email"
        name="email"
        label={label ?? 'E-mailadres'}
        onChange={handleChange}
        icon={Mail}
        addonSide="start"
        value={value}
        touched={touched}
        error={error}
        disabled={disabled}
        autoComplete="email"
        inputMode="email"
        fontSize={fontSize}
      />

      {debouncedSuggestion && suggestion && (
        <Banner $bgColor="gold" $color={theme.colors.grayBlack} $borderColor="transparant">
          <Center block>
            <Small>
              Bedoelde je misschien <u onClick={handleSuggestionClick}>{suggestion}</u>?
            </Small>
          </Center>
        </Banner>
      )}
    </>
  );
};

export default ValidatedEmailInput;
