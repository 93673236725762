import React from 'react';
import { useMeOptional } from '~/hooks/useMe';
import DelayedRedirect from '../DelayedRedirect';

interface LegacyCustomerFilterProps {
  isLegacyRoute: boolean;
}

export const LegacyCustomerFilter: React.FC<LegacyCustomerFilterProps> = ({
  isLegacyRoute,
  children,
}) => {
  const { me } = useMeOptional();
  const isLegacyCustomer = me?.__typename === 'Customer' && me.isLegacyCustomer;

  if (isLegacyRoute && !isLegacyCustomer) {
    return <DelayedRedirect to="/intake/indicaties" timeout={100} />;
  }

  return <>{children}</>;
};

export default LegacyCustomerFilter;
