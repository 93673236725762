import { gql, useMutation, useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { OrderItemFragment } from '~/fragments/OrderItem';
import type { createFunnel, createFunnelVariables } from '~/types/generated/createFunnel';
import type { getMyFunnel, getMyFunnelVariables } from '~/types/generated/getMyFunnel';
import { DutyPackageName } from '~/types/graphql-global-types';
import { useActiveHouseId } from './useActiveHouseId';
import {
  dutyPackagesWithoutIntake,
  useCustomerDutyPackage,
  useCustomerDutyPackages,
} from './useDutyPackage';
import { useIsInitializing } from './useIsInitializing';

const CREATE_FUNNEL = gql`
  mutation createFunnel($houseId: ID!, $dutyPackage: String!, $couponCode: String) {
    createFunnel(houseId: $houseId, dutyPackage: $dutyPackage, couponCode: $couponCode) {
      id
    }
  }
`;

interface FunnelInput {
  dutyPackage: DutyPackageName | string;
  couponCode?: string;
}

export const useStartFunnel = (input: FunnelInput) => {
  const router = useRouter();
  const { activeHouseId } = useActiveHouseId();

  const { packages: intakeSkippingPackages } = useCustomerDutyPackages(dutyPackagesWithoutIntake);
  const redirectToIntake = () => {
    if (router.asPath.includes('/intake')) return;
    if (intakeSkippingPackages.some(dp => dp.key === input.dutyPackage)) {
      return void router.push('/intake/inplannen');
    }
    void router.push('/intake');
  };

  const [createFunnelMutation, { loading, error }] = useMutation<
    createFunnel,
    createFunnelVariables
  >(CREATE_FUNNEL, {
    onCompleted: redirectToIntake,
    onError: e => {
      console.log('Error creating the funnel', e);
      // We can safely redirect to /intake anyways, a fallback to a Complete package will be shown
      redirectToIntake();
    },
  });

  const startFunnel = (houseId?: string) =>
    createFunnelMutation({ variables: { houseId: houseId || activeHouseId, ...input } });

  return {
    startFunnel,
    loading,
    error,
  };
};

// TODO: the Price query expects IDs as type String but should be IDs (like House). Ugly duplication as a workaround.
const GET_MY_FUNNEL = gql`
  ${OrderItemFragment}
  query getMyFunnel($houseId: ID!, $houseIdString: String!) {
    house(id: $houseId) {
      id
      funnel {
        orderItem {
          ...OrderItem
          price(requesterId: "", requesterType: "Lead", houseId: $houseIdString)
        }
        discount {
          id
          code
        }
      }
    }
  }
`;

/**
 * Looks up the Funnel and corresponding OrderItem for the active house (similar to useCustomerDutyPackage).
 * Defaults to a Complete OrderItem if no Funnel was found.
 */
export const useFunnel = () => {
  const { activeHouseId: houseId } = useActiveHouseId();
  const [initialHouseId] = useState(houseId);
  const { isInitializing } = useIsInitializing();

  const {
    data: funnelData,
    error,
    loading,
    refetch,
  } = useQuery<getMyFunnel, getMyFunnelVariables>(GET_MY_FUNNEL, {
    variables: {
      // when house ID changes, avoid re-fetching the package: would be nice in theory, but it breaks re-renders (RequestForm disappears)
      houseId: initialHouseId,
      houseIdString: initialHouseId,
    },
    skip: isInitializing || !initialHouseId,
  });

  useEffect(() => {
    void refetch({ houseId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [houseId]);

  const funnel = funnelData?.house.funnel;
  const dutyPackage = funnelData?.house.funnel?.orderItem;

  const quotesLeadResult = useCustomerDutyPackage(DutyPackageName.quotesLead);

  return {
    houseId: funnelData?.house.id,
    funnel,
    discount: loading ? undefined : funnel?.discount,
    dutyPackage: loading ? undefined : dutyPackage || quotesLeadResult.dutyPackage,
    error: error ? new Error('Er is iets mis gegaan, probeer het later nog eens.') : undefined,
    loading,
  };
};
