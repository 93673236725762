import React, { useContext, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';

type ContextValue = [boolean, Dispatch<SetStateAction<boolean>> | null];

export const IsInitializingContext = React.createContext<ContextValue>([true, null]);

export const IsInitializingProvider: React.FC = ({ children }) => {
  const [isInitializing, setIsInitializing] = useState(true);
  const contextValue: ContextValue = [isInitializing, setIsInitializing];
  return (
    <IsInitializingContext.Provider value={contextValue}>{children}</IsInitializingContext.Provider>
  );
};

export function useIsInitializing() {
  const [isInitializing, setIsInitializing] = useContext(IsInitializingContext);
  return { isInitializing, setIsInitializing: setIsInitializing! };
}
